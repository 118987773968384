import React, { useState, useContext, useEffect } from 'react';
import { GameContext } from './GameContext';
import { useLanguage } from './LanguageContext';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaPlay } from "react-icons/fa6";
import { GoogleGenerativeAI } from "@google/generative-ai";
import './GameSettings.css';
import { ref, set, get } from 'firebase/database';
import { database, GEMINI_CONFIG } from '../firebase';

// firebase.js에서 가져온 설정 사용
const API_KEY = GEMINI_CONFIG.API_KEY;
const API_URL = GEMINI_CONFIG.API_URL;

// Gemini API 초기화
const genAI = new GoogleGenerativeAI(API_KEY);
const model = genAI.getGenerativeModel({
  model: "gemini-2.0-flash-exp"
});

const LoadingSpinner = () => (
  <div className="spinner"></div>
);

const GameSettings = ({ isHost, onStartGame, onClose }) => {
  const { 
    setGameSettings,
    players,
    roomCode
  } = useContext(GameContext);
  const { language: contextLanguage } = useLanguage();
  const [settings, setSettings] = useState({
    language: 'ko',
    category: 'general',
    wordsPerPlayer: '3',
    timeLimit: '-1',
    answerMethod: 'typing'
  });
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    setGameSettings(settings);
    
  }, []);

  const handleSettingsChange = (name, value) => {
    setSettings(prev => {
      let newSettings = {
        ...prev,
        [name]: value
      };

      if (name === 'language') {
        const categoryOptions = getOptionsForSetting('category', value);
        const currentCategory = categoryOptions.find(opt => opt.value === prev.category);
        if (!currentCategory) {
          newSettings.category = categoryOptions[0].value;
        }
      }

      setGameSettings(newSettings);
      return newSettings;
    });
  };

  const getOptionsForSetting = (settingName, languageOverride = settings.language) => {
    switch (settingName) {
      case 'language':
        return [
          { value: 'ko', label: '한국어' },
          { value: 'en', label: 'English' }
        ];
      case 'category':
        if (languageOverride === 'ko') {
          return [
            { value: 'general', label: '아무거나' },
            { value: 'Kpop', label: '아이돌' },
            { value: 'pokemon', label: '포켓몬' },
            { value: 'animals', label: '동물' },
            { value: 'food', label: '음식' },
            { value: 'object', label: '물건' },
            { value: 'body', label: '신체' },
            { value: 'emotion', label: '감정' },
            { value: 'location', label: '장소' },
            { value: 'job', label: '직업' },
            { value: 'sport', label: '스포츠' },
            { value: 'music', label: '음악' },
            { value: 'art', label: '미술' },
            { value: 'movie', label: '영화' },
            { value: 'book', label: '책' },
            { value: 'news', label: '뉴스' },
            { value: 'person', label: '인물' },
          ];
        } else {
          return [
            { value: 'general', label: 'General' },
            { value: 'Kpop', label: 'Kpop' },
            { value: 'pokemon', label: 'Pokemon' },  
            { value: 'animals', label: 'Animals' },
            { value: 'food', label: 'Food' },
            { value: 'object', label: 'Object' },
            { value: 'body', label: 'Body' },
            { value: 'emotion', label: 'Emotion' },
            { value: 'location', label: 'Location' },
            { value: 'job', label: 'Job' },
            { value: 'sport', label: 'Sport' },
            { value: 'music', label: 'Music' },
            { value: 'art', label: 'Art' },
            { value: 'movie', label: 'Movie' },
            { value: 'book', label: 'Book' },

            { value: 'person', label: 'Person' },
          ];
        }
      case 'wordsPerPlayer':
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => ({
          value: num.toString(),
          label: num.toString()
        }));
      case 'timeLimit':
        return [
          { value: '-1', label: languageOverride === 'ko' ? '무제한' : 'Unlimited' },
          { value: '60', label: `1${languageOverride === 'ko' ? '분' : 'min'}` },
          { value: '120', label: `2${languageOverride === 'ko' ? '분' : 'min'}` },
          { value: '180', label: `3${languageOverride === 'ko' ? '분' : 'min'}` }
        ];
      case 'answerMethod':
        return [
          { 
            value: 'typing', 
            label: languageOverride === 'ko' ? '타이핑' : 'Typing'
          },
          { 
            value: 'handRaise', 
            label: languageOverride === 'ko' ? '손들기' : 'Hand Raise'
          }
        ];
      default:
        return [];
    }
  };

  const handlePrevOption = (name) => {
    const options = getOptionsForSetting(name);
    const currentIndex = options.findIndex(opt => opt.value === settings[name]);
    const newIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
    handleSettingsChange(name, options[newIndex].value);
  };

  const handleNextOption = (name) => {
    const options = getOptionsForSetting(name);
    const currentIndex = options.findIndex(opt => opt.value === settings[name]);
    const newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;
    handleSettingsChange(name, options[newIndex].value);
  };

  const getCurrentLabel = (settingName) => {
    const options = getOptionsForSetting(settingName);
    const currentOption = options.find(opt => opt.value === settings[settingName]);
    return currentOption ? currentOption.label : '';
  };

  const generateWordList = async () => {
    setIsGenerating(true);
    const totalWords = players.length * parseInt(settings.wordsPerPlayer);
    const minimumWords = Math.max(50, totalWords * 2); // 최소 50개
    
    try {
      if (!API_KEY) {
        throw new Error('API key is not configured');
      }

      const requestData = {
        contents: [{
          parts: [{
            text: `Generate ${minimumWords} unique and diverse ${settings.category} words in ${settings.language === 'ko' ? 'Korean' : 'English'} language.
            Return only as a JSON array of single words.
            For example: ["word1", "word2", "word3"]
            
            Important Rules:
            - Each word MUST be completely unique and different from others
            - NO similar sounding or looking words allowed
            - NO variations of the same word (e.g., if "cat" exists, don't use "cats")
            - Mix different themes within the category
            - Words should be well-known and easily recognizable
            - Maximum word length: 12 characters
            
            Category specific rules for ${settings.category}:
            ${settings.category === 'general' ? 'General words, Use words from different areas of daily life (home, work, nature, etc.)' : 
              settings.category === 'animals' ? 'Animals, Mix different types: pets, wild animals, sea creatures, insects, birds. No similar species.' : 
              settings.category === 'food' ? 'Foods, Mix cuisines, dishes, beverages. No similar items.' :
              settings.category === 'object' ? 'Objects, Mix categories: furniture, tools, electronics, toys, etc. No related items.' :
              settings.category === 'body' ? 'Body parts, organs, bones. No similar parts.' :
              settings.category === 'emotion' ? 'Emotions, Use distinct emotions, no synonyms or similar feelings.' :
              settings.category === 'location' ? 'Locations, Mix types: countries, cities, nature spots, buildings. No nearby places.' :
              settings.category === 'job' ? 'Jobs, Mix industries and levels. No related positions.' :
              settings.category === 'sport' ? 'Sports, Include different types of sports and activities. No similar games.' :
              settings.category === 'Kpop' ? 'Kpop group or artist name. Mix different generations and groups. No similar names.' :
              settings.category === 'art' ? 'Art Titles, Mix different art forms and styles. No similar concepts.' :
              settings.category === 'movie' ? 'Kids Movie Titles, Mix genres and eras. No similar titles.' :
              settings.category === 'book' ? 'Kids Book Titles, Mix genres and types. No similar titles.' :
              settings.category === 'pokemon' ? 'Pokemon name, Mix generations and types. No similar species.' :
              settings.category === 'news' ? 'Latest Easy News keyword, Mix different easy news topics: politics, tech, culture, etc. No related terms.' :
              settings.category === 'person' ? 'Famous person. Mix professions and eras. No related personalities.' :
              ''}
            
            Return in valid JSON format only.`
          }]
        }],
        generationConfig: {
          temperature: 1.4,    // 더 높은 랜덤성
          topP: 0.9,          // 더 다양한 선택
          topK: 40,           // 선택 폭 증가
          maxOutputTokens: 1024  // 토큰 수 증가
        }
      };

      const response = await fetch(`${API_URL}?key=${API_KEY}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API request failed: ${response.status} - ${errorData.error?.message || response.statusText}`);
      }

      const data = await response.json();
      const text = data.candidates[0].content.parts[0].text;
      
      let wordList;
      try {
        const cleanedText = text.replace(/```json\n|\n```/g, '').trim();
        wordList = JSON.parse(cleanedText);
        
        if (!Array.isArray(wordList)) {
          throw new Error('올바른 단어 리스트 형식이 아닙니다.');
        }

        // 중복 제거 및 필터링
        wordList = [...new Set(wordList)]
          .filter(word => word.length <= 12)
          .filter(word => /^[가-힣a-zA-Z\s]+$/.test(word));

        // 충분한 단어가 없는 경우 에러
        if (wordList.length < totalWords) {
          throw new Error('생성된 단어가 충분하지 않습니다.');
        }

        // 3번 셔플하여 더 무작위로 만들기
        for (let i = 0; i < 3; i++) {
          wordList = shuffleArray([...wordList]);
        }
        
        // 앞에서부터 필요한 만큼만 선택
        const selectedWords = wordList.slice(0, totalWords);

        // Firebase에 저장
        await set(ref(database, `rooms/${roomCode}/gameData`), {
          settings: {
            ...settings,
            totalWords,
            currentWordIndex: 0
          },
          wordList: selectedWords,
          currentWord: selectedWords[0]
        });

        onStartGame();
      } catch (parseError) {
        console.error('JSON 파싱 에러:', parseError);
        throw new Error(`단어 리스트 파싱 실패: ${parseError.message}`);
      }
    } catch (error) {
      console.error('Error generating word list:', error);
      alert(settings.language === 'ko' ? 
        `단어 생성 중 오류가 발생했습니다: ${error.message}` : 
        `Error generating word list: ${error.message}`);
    } finally {
      setIsGenerating(false);
    }
  };

  // 향상된 Fisher-Yates 셔플 알고리즘
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      // 더 강력한 랜덤성을 위해 암호학적으로 안전한 난수 생성
      const j = Math.floor(Math.random() * (i + 1));
      // ES6 구조분해할당을 사용한 swap
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleStartGame = async () => {
    if (isHost) {
      await set(ref(database, `rooms/${roomCode}/scores`), {});
      await generateWordList();
    }
  };

  return (
    <div className="game-settings">
      <button className="close-button" onClick={onClose}>
        <IoClose />
      </button>
      <h3>{contextLanguage === 'en' ? 'Game Settings' : '게임 설정'}</h3>
      <div className="settings-grid">
        {[
          'language', 
          'category', 
          'wordsPerPlayer', 
          'timeLimit',
          // 'answerMethod'
        ].map((settingName) => (
          <div key={settingName} className="setting-item">
            <label>
              {contextLanguage === 'en' ? 
                settingName === 'language' ? 'Language' :
                settingName === 'category' ? 'Category' :
                settingName === 'wordsPerPlayer' ? 'Words / Player' :
                settingName === 'timeLimit' ? 'Time Limit' :
                'Answer Method'
                :
                settingName === 'language' ? '언어' :
                settingName === 'category' ? '카테고리' :
                settingName === 'wordsPerPlayer' ? '1인당 낱말 수' :
                settingName === 'timeLimit' ? '제한 시간' :
                '맞히는 방식'
              }
            </label>
            <div className="setting-selector">
              <button 
                className="arrow-button"
                onClick={() => handlePrevOption(settingName)}
              >
                <IoIosArrowBack />
              </button>
              <div className="current-value">
                {getCurrentLabel(settingName)}
              </div>
              <button 
                className="arrow-button"
                onClick={() => handleNextOption(settingName)}
              >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        ))}
      </div>
      {isHost && (
        <div className="settings-buttons">
          <button 
            className="start-button" 
            onClick={handleStartGame}
            disabled={isGenerating}
          >
            {isGenerating ? (
              <LoadingSpinner />
            ) : (
              <FaPlay />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default GameSettings;
