import React, { useState, useContext, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import { GameContext } from './GameContext';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook
import GameSettings from './GameSettings';
import { FaPlay } from "react-icons/fa6";
import './WaitingRoom.css';


const WaitingRoom = () => {
  const { roomCode, players, role, startGame, setGameSettings, setStep, gameStarted } = useContext(GameContext);
  const { language } = useLanguage();
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    // Room and players information logging
    console.log('WaitingRoom updated: roomCode', roomCode, 'players', players);
  }, [roomCode, players]);

  useEffect(() => {
    if (roomCode) {
      const settingsRef = ref(database, `rooms/${roomCode}/gameSettings`);
      onValue(settingsRef, (snapshot) => {
        const settingsData = snapshot.val();
        if (settingsData) {
          setGameSettings(settingsData);
        }
      });

      const gameStartedRef = ref(database, `rooms/${roomCode}/gameStarted`);
      onValue(gameStartedRef, (snapshot) => {
        const gameStartedData = snapshot.val();
        if (gameStartedData) {
          setStep(3);
        }
      });
    }
  }, [roomCode, setGameSettings, setStep]);

  if (gameStarted) {
    return null;
  }

  const handleStartClick = () => {
    setShowSettings(true);
  };

  return (
    <div className="waiting-room">
      <div className="room-code-container">
        <h1>{language === 'en' ? 'Room Code' : '방 번호'}</h1>
        <div className="room-code-cards">
          {roomCode.split('').map((digit, index) => (
            <div key={index} className="code-card">
              {digit}
            </div>
          ))}
        </div>
      </div>

      <h3>{language === 'en' ? 'Players' : '참가자'}</h3>
      <ul className='players-list'>
        {players.map(player => (
          <li key={player.id}> 
            <span className="player-id">{player.id}</span> 
            <span className="player-name">{player.name}</span>
          </li>
        ))}
      </ul> 

      {role === 'host' && !showSettings && (
        <div className='waiting-room-buttons'>
          <button className='start-game' onClick={handleStartClick}>
            <FaPlay />
          </button>
        </div>
      )}

      {showSettings && (
        <div className="settings-overlay">
          <GameSettings 
            isHost={role === 'host'} 
            onStartGame={startGame}
            onClose={() => setShowSettings(false)}
          />
        </div>
      )}

      {role === 'guest' && (
        <div className="waiting-message">
          {showSettings ? 
            (language === 'en' ? 'Host is configuring game settings...' : '호스트가 게임 설정 중입니다...') :
            (language === 'en' ? 'Waiting for the host to start the game...' : '호스트가 게임을 시작하기를 기다리는 중...')
          }
        </div>
      )}
    </div>
  );
};

export default WaitingRoom;
