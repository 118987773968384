import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import { GameContext } from './GameContext';
import { database } from '../firebase';
import { ref, onValue, push, set } from 'firebase/database';
import throttle from 'lodash/throttle';
import './Whiteboard.css';
import { FaBomb } from "react-icons/fa";


const Whiteboard2 = ({ canDraw, throttleValue = 75 }) => {
  const canvasRef = useRef(null);
  const { 
    roomCode, 
    currentDrawer, 
    playerID, 
    isDrawing, 
    setIsDrawing,
    players 
  } = useContext(GameContext);
  
  const isDrawer = playerID === currentDrawer;
  const [tool, setTool] = useState('pen');
  const [penSize, setPenSize] = useState(5);
  const [eraserSize, setEraserSize] = useState(10);
  const drawingQueue = useRef([]);
  const compressedQueue = useRef([]);
  const points = useRef([]);
  const lastPoint = useRef(null);
  const [penColor, setPenColor] = useState('black');

  const getOffset = (e, rect, canvas) => {
    const offsetX = (e.clientX || e.touches[0].clientX) - rect.left;
    const offsetY = (e.clientY || e.touches[0].clientY) - rect.top;
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    return { x: offsetX * scaleX, y: offsetY * scaleY };
  };


  const drawCurve = (ctx, pts, tool, size, color) => {
    if (pts.length < 2) return;

    ctx.lineWidth = size;
    ctx.strokeStyle = tool === 'pen' ? color : 'white';
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';

    ctx.beginPath();
    ctx.moveTo(pts[0].x, pts[0].y);

    for (let i = 1; i < pts.length; i++) {
      const xc = (pts[i].x + pts[i - 1].x) / 2;
      const yc = (pts[i].y + pts[i - 1].y) / 2;
      ctx.quadraticCurveTo(pts[i - 1].x, pts[i - 1].y, xc, yc);
    }

    ctx.stroke();
  };

  const throttledHandleMove = useCallback(throttle((e, ctx, canvas, drawing, tool, penSize, eraserSize) => {
    if (!drawing.current) return;
    const rect = canvas.getBoundingClientRect();
    const currentPoint = getOffset(e, rect, canvas);
    
    const minDistance = 10;
    if (points.current.length > 0) {
      const lastPoint = points.current[points.current.length - 1];
      const distance = Math.sqrt(
        Math.pow(currentPoint.x - lastPoint.x, 2) + 
        Math.pow(currentPoint.y - lastPoint.y, 2)
      );
      if (distance < minDistance) return;
    }

    points.current.push(currentPoint);
    
    drawCurve(ctx, points.current, tool, tool === 'pen' ? penSize : eraserSize, penColor);

    compressedQueue.current.push([
      currentPoint.x,
      currentPoint.y,
      tool === 'pen' ? 0 : 1,
      tool === 'pen' ? penSize : eraserSize,
      penColor,
      points.current.length === 1 ? 'begin' : 'continue'
    ]);

  }, throttleValue), [tool, penSize, eraserSize, penColor]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // 배경색을 흰색으로 설정
    canvas.style.backgroundColor = 'white';
    
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    let drawing = { current: false };

    const handleStart = (e) => {
      if (!canDraw) return;
      e.preventDefault();
      drawing.current = true;
      points.current = [];
      const rect = canvas.getBoundingClientRect();
      const point = getOffset(e, rect, canvas);
      points.current.push(point);
      
      compressedQueue.current.push([
        point.x,
        point.y,
        tool === 'pen' ? 0 : 1,
        tool === 'pen' ? penSize : eraserSize,
        penColor,
        'begin'
      ]);
    };

    const handleMove = (e) => {
      if (!canDraw || !drawing.current) return;
      e.preventDefault();
      throttledHandleMove(e, ctx, canvas, drawing, tool, penSize, eraserSize);
    };

    const handleEnd = () => {
      if (!canDraw) return;
      drawing.current = false;
      points.current = [];
      sendBatchToDatabase();
    };

    const sendBatchToDatabase = () => {
      if (compressedQueue.current.length > 0) {
        const batch = compressedQueue.current;
        compressedQueue.current = [];
        push(ref(database, `rooms/${roomCode}/draw`), batch);
      }
    };

    const drawFromFirebase = (data) => {
      // ctx.clearRect(0, 0, canvas.width, canvas.height);
      // ctx.fillStyle = 'white';
      // ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      console.log('Drawing points:', data.length); // 그리기 시작
      let currentPath = [];  // 현재 그리고 있는 경로를 저장
      let currentTool, currentSize, currentColor;  // 현재 경로의 속성을 저장

      data.forEach(([x, y, tool, size, color, type]) => {
        if (type === 'begin') {
          // 이전 경로 그리기
          if (currentPath.length > 0) {
            drawCurve(ctx, currentPath, currentTool === 0 ? 'pen' : 'eraser', currentSize, currentColor);
          }
          // 새로운 경로 시작과 속성 저장
          currentPath = [{ x, y }];
          currentTool = tool;
          currentSize = size;
          currentColor = color;
        } else {
          currentPath.push({ x, y });
          if (currentPath.length >= 3) {
            drawCurve(ctx, currentPath, currentTool === 0 ? 'pen' : 'eraser', currentSize, currentColor);
          }
        }
      });
      
      // 마지막 경로 처리
      if (currentPath.length > 0) {
        drawCurve(ctx, currentPath, currentTool === 0 ? 'pen' : 'eraser', currentSize, currentColor);
      }

        
    };

    canvas.addEventListener('mousedown', handleStart);
    canvas.addEventListener('mouseup', handleEnd);
    canvas.addEventListener('mousemove', handleMove);
    canvas.addEventListener('touchstart', handleStart);
    canvas.addEventListener('touchend', handleEnd);
    canvas.addEventListener('touchmove', handleMove);

    const drawRef = ref(database, `rooms/${roomCode}/draw`);
    const unsubscribe = onValue(drawRef, (snapshot) => {  // onValue의 반환값을 저장
      const data = snapshot.val();
      console.log('Received drawing data:', data);
      
      if (data) {
        const coordinates = Object.values(data).flat();
        console.log('Processing coordinates:', coordinates);
        drawFromFirebase(coordinates);
      } else {  // data가 null일 때 (clear 했을 때)
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
});

    return () => {
      canvas.removeEventListener('mousedown', handleStart);
      canvas.removeEventListener('mouseup', handleEnd);
      canvas.removeEventListener('mousemove', handleMove);
      canvas.removeEventListener('touchstart', handleStart);
      canvas.removeEventListener('touchend', handleEnd);
      canvas.removeEventListener('touchmove', handleMove);
      unsubscribe();
    };
  }, [canDraw, tool, penSize, eraserSize, penColor, throttledHandleMove]);

  // 드로어가 바뀔 때마다 캔버스와 Firebase 데이터 클리어
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // 로컬 큐 초기화
      drawingQueue.current = [];
      compressedQueue.current = [];
      points.current = [];
      lastPoint.current = null;

      // Firebase 그림 데이터 초기화
      const drawingRef = ref(database, `rooms/${roomCode}/drawing`);
      set(drawingRef, null);
    }
  }, [currentDrawer, roomCode]);

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    set(ref(database, `rooms/${roomCode}/draw`), null);
  };
  
  const drawerStyle = {
    backgroundColor: 'transparent',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 0 10px 20px rgba(255, 152, 0, 0.5)',
  };

  const ColorSlider = ({ onColorChange, currentColor }) => {
    const [isDragging, setIsDragging] = useState(false);
    const sliderRef = useRef(null);
    const [selectedColor, setSelectedColor] = useState(currentColor);

    // 24색상 정의
    const colors = [
      // 무채색
      '#000000', '#404040', '#808080', '#C0C0C0', '#FFFFFF',
      // 분홍 계열
      '#E4007F', '#F5A9C8',
      // 빨강 계열
      '#E60012', '#FF6B6B', '#FFAFAF',  // 순수 빨강 대신 약간 어둡게
      // 주황 계열
      '#F39800', '#FFAA42',  // 더 부드러운 주황
      // 노랑 계열
      '#FFF100', '#FFF6A5',  // 순수 노랑 대신 약간 부드럽게
      // 초록 계열
      '#009944', '#7AC92A', '#B3E085',  // 자연스러운 초록
      // 청록 계열
      '#00A0C1', '#68C9D3',  // 부드러운 청록
      // 파랑 계열
      '#0068B7', '#4C96D7', '#89C3EB',  // 차분한 파랑
      // 보라 계열
      '#920783', '#C657A3',  // 부드러운 보라
      // 갈색 계열
      '#8B4513', '#A36336', '#C69C6D',  // 자연스러운 갈색
    ];

    const handleColorSelect = useCallback((clientX) => {
      if (!sliderRef.current) return;
      
      const rect = sliderRef.current.getBoundingClientRect();
      // 슬라이더 영역을 벗어나지 않도록 제한
      const x = Math.max(0, Math.min(clientX - rect.left, rect.width));
      const width = rect.width;
      
      const index = Math.min(Math.floor((x / width) * colors.length), colors.length - 1);
      const newColor = colors[index];
      setSelectedColor(newColor);
      onColorChange(newColor);
    }, [onColorChange]);

    const handleStart = useCallback((e) => {
      e.preventDefault();
      setIsDragging(true);
      const clientX = e.type.includes('touch') ? e.touches[0].clientX : e.clientX;
      handleColorSelect(clientX);
    }, [handleColorSelect]);

    const handleMove = useCallback((e) => {
      if (!isDragging) return;
      e.preventDefault();
      const clientX = e.type.includes('touch') ? e.touches[0].clientX : e.clientX;
      handleColorSelect(clientX);
    }, [isDragging, handleColorSelect]);

    const handleEnd = useCallback(() => {
      setIsDragging(false);
    }, []);

    useEffect(() => {
      if (isDragging) {
        window.addEventListener('mousemove', handleMove);
        window.addEventListener('touchmove', handleMove, { passive: false });
        window.addEventListener('mouseup', handleEnd);
        window.addEventListener('touchend', handleEnd);
      }

      return () => {
        window.removeEventListener('mousemove', handleMove);
        window.removeEventListener('touchmove', handleMove);
        window.removeEventListener('mouseup', handleEnd);
        window.removeEventListener('touchend', handleEnd);
      };
    }, [isDragging, handleMove, handleEnd]);

    // 현재 선택된 색상의 인덱스 찾기
    const currentIndex = colors.indexOf(selectedColor);
    // 핸들 위치 계산
    const position = currentIndex !== -1 ? (currentIndex / (colors.length - 1)) * 100 : 0;

    return (
      <div className="color-slider-container">
        <div 
          ref={sliderRef}
          className="color-spectrum"
          onMouseDown={handleStart}
          onTouchStart={handleStart}
          style={{
            background: `linear-gradient(to right, ${colors.join(', ')})`
          }}
        />
        <div 
          className={`color-picker-handle ${isDragging ? 'dragging' : ''}`}
          style={{ 
            backgroundColor: selectedColor,
            left: `${position}%`,
            transform: 'translateX(-50%)'
          }}
        />
      </div>
    );
  };

  return (
    <div className='drawing-window'>
      <div className={`drawing-area ${isDrawer ? 'drawer' : ''}`}>
        <div className="drawing-tools-container">
          {!isDrawer && (
            <div className="drawing-overlay">
              <span className="drawing-message">
                {players.find(p => p.id === currentDrawer)?.name || 'Someone'} is drawing...
              </span>
            </div>
          )}

          {/* 첫 번째 줄: 펜 크기, 지우개, 전체 지우기 */}
          <div className="tools-group">
            {[3, 5, 8, 12].map((size) => (
              <span
                key={size}
                onClick={() => {
                  if (isDrawer) {
                    setTool('pen');
                    setPenSize(size);
                  }
                }}
                className={`pen-button ${tool === 'pen' && penSize === size ? 'selected' : ''}`}
                style={{
                  width: size * 2,
                  height: size * 2,
                  backgroundColor: penColor
                }}
              />
            ))}
            
            {[10, 20, 30].map((size) => (
              <span
                key={size}
                onClick={() => {
                  if (isDrawer) {
                    setTool('eraser');
                    setEraserSize(size);
                  }
                }}
                className={`eraser-button ${tool === 'eraser' && eraserSize === size ? 'selected' : ''}`}
                style={{
                  width: size * 0.7,
                  height: size * 0.7
                }}
              />
            ))}
            
            <button 
              className="clear-button"
              onClick={() => isDrawer && clearCanvas()}
              title="Clear Canvas"
            >
              <FaBomb size={12} />
            </button>
          </div>

          {/* 두 번째 줄: 색상 슬라이더 */}
          <div className="color-slider-row">
            <ColorSlider 
              onColorChange={(color) => {
                if (isDrawer) {
                  setPenColor(color);
                  if (tool === 'eraser') setTool('pen');
                }
              }}
              currentColor={penColor}
            />
          </div>
        </div>

        <canvas
          ref={canvasRef}
          width={400}
          height={400}
          className="canvas"
        />
      </div>
    </div>
  );
};

export default Whiteboard2;
