import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBOP1I9mPEWBINNyoJKDIfGoflzExB2Nig",
  authDomain: "drawquiz2024.firebaseapp.com",
  projectId: "drawquiz2024",
  storageBucket: "drawquiz2024.appspot.com",
  messagingSenderId: "1005944151359",
  appId: "1:1005944151359:web:23f82517314dbff6d7078a",
};

export const GEMINI_CONFIG = {
  API_KEY: "AIzaSyBNKCyAFVmoOCWIkQ-FfIolrLLv51HllQo",
  API_URL: "https://generativelanguage.googleapis.com/v1beta/models/gemini-2.0-flash-exp:generateContent"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app);

export { database, firestore };
