import React, { useContext, useEffect, useState, useCallback } from 'react';
import { GameContext } from './GameContext';
import { FaInfinity } from 'react-icons/fa';
import Whiteboard2 from './Whiteboard2';
import './GameScreen.css';
import Logo from './Logo';
import { ref, set, onValue, get } from 'firebase/database';
import { database } from '../firebase';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook
import { RiRestartLine } from "react-icons/ri";
import GameSettings from './GameSettings'; // GameSettings 컴포넌트 import 추가

// 상수를 파일 최상단에 선언
const UNLIMITED_TIME = -1;

const RankingPopup = ({ scores, onReplay, onFirstTime, isHost }) => {
  const sortedScores = Object.entries(scores).sort((a, b) => b[1] - a[1]);

  return (
    <div className='ranking-bg'>
    <div className="ranking-popup">
      <h2 style={{ textAlign: 'center' }}>Ranking</h2>
      <div className="ranking-list">
        {sortedScores.map(([name, score], index) => (
          <div key={name} className="ranking-item">
            {index + 1}. {name} - {score} p
          </div>
        ))}
      </div>
      {isHost && (
        <div className="ranking-actions">
          <button onClick={onReplay}><RiRestartLine />          </button>
          <button onClick={onFirstTime}>Home </button>
        </div>
      )}
    </div>
    </div>
  );
};

const PlayerListForDrawer = ({ players, currentDrawer, scores, handleMarkCorrect }) => {
  const [confirmPlayer, setConfirmPlayer] = useState(null);
  const { language } = useLanguage();

  const handlePlayerClick = (player) => {
    if (currentDrawer !== player.id) {
      setConfirmPlayer(player);
    }
  };

  const confirmMarkCorrect = () => {
    handleMarkCorrect(confirmPlayer.name);
    setConfirmPlayer(null);
  };

  return (
    <div className="players-list drawer-list">
      {players.map((player, index) => (
        <div 
          key={player.id}
          className={`player ${currentDrawer === player.id ? 'drawing-player' : ''}`}
          onClick={() => handlePlayerClick(player)}
        >
          <div className="player-info">
            <span className="player-number">{index + 1}</span>
            <span className="player-name">
              {player.name}
            </span>
            <span className="player-score">{scores[player.name] || 0}p</span>
          </div>
        </div>
      ))}

      {confirmPlayer && (
        <div className="confirm-popup-bg">
          <div className="confirm-popup">
            <p>{language === 'en' ? `Give points to ${confirmPlayer.name}?` : `${confirmPlayer.name}님에게 점수를 주시겠습니까?`}</p>
            <div className="confirm-actions">
              <button onClick={confirmMarkCorrect}>{language === 'en' ? 'Yes' : '예'}</button>
              <button onClick={() => setConfirmPlayer(null)}>{language === 'en' ? 'No' : '아니오'}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PlayerListForNonDrawer = ({ players, currentDrawer, scores }) => (
  <div className="players-list non-drawer-list">
    {players.map((player, index) => (
      <div 
        key={player.id}
        className={`player ${currentDrawer === player.id ? 'drawing-player' : ''}`}
      >
        <div className="player-info">
          <span className="player-number">{index + 1}</span>
          <span className="player-name">{player.name}</span>
          <span className="player-score">{scores[player.name] || 0}p</span>
        </div>
      </div>
    ))}
  </div>
);

const Timer = ({ timeLimit, timeLeft }) => {
  // 무제한(-1) 체크를 문자열과 숫자 모두 처리
  if (timeLimit === '-1' || timeLimit === -1 || timeLeft === null) {
    return null;
  }

  const progress = Math.max(0, Math.min(100, (timeLeft / timeLimit) * 100));

  return (
    <div className="timer-container">
      <div className="timer-bar">
        <div 
          className="timer-progress" 
          style={{ 
            width: `${progress}%`,
            backgroundColor: timeLeft <= 10 ? '#ff4444' : '#000000'
          }} 
        />
      </div>
      <span className="timer-text">{Math.max(0, timeLeft)}s</span>
    </div>
  );
};

const GameScreen = () => {
  const { 
    gameSettings, 
    setGameSettings,  // GameContext에서 setGameSettings 추가
    players, 
    currentDrawer, 
    scores, 
    currentWord,
    setCurrentWord,
    isDrawing, 
    setIsDrawing, 
    role, 
    roomCode, 
    endGame,
    handleKeywordInput,
    checkAnswer,
    isTypingKeyword,
    setIsTypingKeyword,
    correctGuess,
    showCorrectPopup,
    setShowCorrectPopup,
    showRankingPopup,
    setShowRankingPopup,
    hostName,
    guestName,
    markCorrectAnswer,
    updateScore,
    nextDrawer,
    playerID,
    setPlayers,
    setScores,
    setStep,
    setCorrectGuess // Add this line to destructure setCorrectGuess from context
  } = useContext(GameContext);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timerStartTime, setTimerStartTime] = useState(null);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [answer, setAnswer] = useState('');
  const [keywordEntered, setKeywordEntered] = useState(false);
  const { language } = useLanguage(); // Use the useLanguage hook
  const [showEndGameConfirm, setShowEndGameConfirm] = useState(false);
  const [showGameSettings, setShowGameSettings] = useState(true);
  const [localTimeLeft, setLocalTimeLeft] = useState(null);
  const [showEndOptions, setShowEndOptions] = useState(false);
  const [isTypingAllowed, setIsTypingAllowed] = useState(true);  // canType을 isTypingAllowed로 변경
  const [isUnlimited, setIsUnlimited] = useState(gameSettings.timeLimit === UNLIMITED_TIME);

  // 무제한 모드 체크
  // const isUnlimited = gameSettings.timeLimit === -1;

  // 그리기 권한 조건 수정
  const canDraw = playerID === currentDrawer && !showCorrectPopup && (
    gameSettings.timeLimit === '-1' || gameSettings.timeLimit === -1 || // 무제한 모드
    (timeLeft > 0) // 타이머 모드
  );

  // 그리기 및 타이핑 권한 조건 설정
  const canType = playerID !== currentDrawer;  // 이 상수는 유지

  // 디버깅을 위한 콘솔 로그
  useEffect(() => {
    console.log('Drawing conditions:', {
      playerID,
      currentDrawer,
      showCorrectPopup,
      timeLeft,
      isUnlimited,
      canDraw
    });
  }, [playerID, currentDrawer, showCorrectPopup, timeLeft, isUnlimited, canDraw]);

  // 타이머 관련 상태 초기값 설정
  const DEFAULT_TIME_LIMIT = 60;

  // 타이머 관련 상태 업데이트 함수
  const updateTimerState = useCallback((timerState) => {
    if (gameSettings.timeLimit === UNLIMITED_TIME || !role === 'host') return;
    
    set(ref(database, `rooms/${roomCode}/timer`), timerState);
  }, [gameSettings.timeLimit, role, roomCode]);

  // 타이머 시작 함수 수정
  const startTimer = useCallback(() => {
    if (gameSettings.timeLimit === UNLIMITED_TIME) return;
    
    if (!showGameSettings && currentDrawer && role === 'host' && !showCorrectPopup) {
      const timeLimit = Number(gameSettings.timeLimit);
      updateTimerState({
        timeLeft: timeLimit,
        isActive: true,
        timeLimit: timeLimit
      });
    }
  }, [showGameSettings, currentDrawer, role, showCorrectPopup, gameSettings.timeLimit, updateTimerState]);

  // handleMarkCorrect 함수 수정
  const handleMarkCorrect = useCallback((playerName) => {
    if (!playerName) return;
    
    const currentScore = scores[playerName] || 0;
    const pointsToAdd = 10;
    const newScore = Number(currentScore) + pointsToAdd;
    
    if (!isNaN(newScore)) {
      // 점수 업데이트
      const scoreRef = ref(database, `rooms/${roomCode}/scores/${playerName}`);
      set(scoreRef, newScore)
        .then(() => {
          setScores(prev => ({
            ...prev,
            [playerName]: newScore
          }));

          // 정답자 표시 및 팝업
          setCorrectGuess(playerName);
          setShowCorrectPopup(true);

          // 3초 후 다음 턴
          setTimeout(async () => {
            setShowCorrectPopup(false);
            
            try {
              // 현재 게임 데이터 가져오기
              const gameDataRef = ref(database, `rooms/${roomCode}/gameData`);
              const snapshot = await get(gameDataRef);
              const gameData = snapshot.val();

              // 다음 단어 인덱스 계산
              const nextWordIndex = (gameData.settings.currentWordIndex + 1) % gameData.wordList.length;
              const nextWord = gameData.wordList[nextWordIndex];

              // 다음 그리는 사람 설정
              const currentIndex = players.findIndex(p => p.id === currentDrawer);
              const nextIndex = (currentIndex + 1) % players.length;
              const nextDrawerId = players[nextIndex].id;

              // Firebase 업데이트
              const updates = {
                [`rooms/${roomCode}/currentDrawer`]: nextDrawerId,
                [`rooms/${roomCode}/gameData/settings/currentWordIndex`]: nextWordIndex,
                [`rooms/${roomCode}/gameData/currentWord`]: nextWord
              };

              // 타이머 상태 추가 (시간제한 모드인 경우)
              if (gameSettings.timeLimit !== UNLIMITED_TIME) {
                updates[`rooms/${roomCode}/timer`] = {
                  timeLeft: Number(gameSettings.timeLimit),
                  isActive: true,
                  timeLimit: Number(gameSettings.timeLimit)
                };
              }

              // 모든 업데이트를 한 번에 실행
              await set(ref(database, `rooms/${roomCode}`), {
                ...gameData,
                currentDrawer: nextDrawerId,
                gameData: {
                  ...gameData,
                  settings: {
                    ...gameData.settings,
                    currentWordIndex: nextWordIndex
                  },
                  currentWord: nextWord
                }
              });

            } catch (error) {
              console.error('Turn update error:', error);
            }
          }, 3000);
        })
        .catch(error => {
          console.error('Score update error:', error);
        });
    }
  }, [scores, roomCode, players, currentDrawer, gameSettings.timeLimit, setCorrectGuess, setShowCorrectPopup]);

  // 타이머 업데이트 함수 수정
  const updateTimer = useCallback(() => {
    // 무제한 모드면 타이머 업데이트 하지 않음
    if (gameSettings.timeLimit === UNLIMITED_TIME) return;
    
    if (role === 'host' && isTimerActive && timeLeft > 0) {
      const newTimeLeft = timeLeft - 1;
      
      const timerState = {
        timeLeft: newTimeLeft,
        isActive: newTimeLeft > 0,
        timeLimit: gameSettings.timeLimit
      };

      try {
        set(ref(database, `rooms/${roomCode}/timer`), timerState);
        
        if (newTimeLeft === 0) {
          nextDrawer();
        }
      } catch (error) {
        console.error('Timer update error:', error);
      }
    }
  }, [role, isTimerActive, timeLeft, gameSettings.timeLimit, roomCode]);

  // 타이머 인터벌 설정
  useEffect(() => {
    let interval;
    
    if (role === 'host' && isTimerActive && timeLeft > 0) {
      interval = setInterval(() => {
        updateTimer();
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [role, isTimerActive, timeLeft, updateTimer]);

  // Firebase 타이머 상태 구독
  useEffect(() => {
    if (!roomCode) return;

    const timerRef = ref(database, `rooms/${roomCode}/timer`);
    const unsubscribe = onValue(timerRef, (snapshot) => {
      const data = snapshot.val();
      
      if (data && data.timeLimit !== UNLIMITED_TIME) {
        setTimeLeft(Number(data.timeLeft));
        setIsTimerActive(Boolean(data.isActive));
        if (typeof data.timeLimit === 'number') {
          gameSettings.timeLimit = data.timeLimit;
        }
      } else {
        setTimeLeft(null);
        setIsTimerActive(false);
      }
    });

    return () => unsubscribe();
  }, [roomCode]);

  // 게임 설정 변경 감지 및 타이머 초기화
  useEffect(() => {
    if (role === 'host') {
      if (gameSettings.timeLimit === UNLIMITED_TIME) {
        // 무제한 모드로 변경 시 타이머 데이터 삭제
        set(ref(database, `rooms/${roomCode}/timer`), null);
      } else {
        // 일반 타이머 모드로 변경 시 타이머 초기화
        set(ref(database, `rooms/${roomCode}/timer`), {
          timeLeft: gameSettings.timeLimit,
          isActive: false,
          timeLimit: gameSettings.timeLimit
        });
      }
    }
  }, [gameSettings.timeLimit, role, roomCode]);

  // 타이머 카운트다운 처리 (호스트만)
  useEffect(() => {
    if (gameSettings.timeLimit === UNLIMITED_TIME || role !== 'host') return;
    
    let interval;
    
    if (isTimerActive && timeLeft > 0) {
      interval = setInterval(() => {
        const newTimeLeft = timeLeft - 1;
        set(ref(database, `rooms/${roomCode}/timer`), {
          timeLeft: newTimeLeft,
          isActive: newTimeLeft > 0,
          timeLimit: gameSettings.timeLimit
        });

        if (newTimeLeft === 0) {
          nextDrawer();
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [role, isTimerActive, timeLeft, gameSettings.timeLimit, roomCode]);

  // 게임 시작 시 타이머 초기화
  useEffect(() => {
    if (currentDrawer && role === 'host' && !showGameSettings) {
      startTimer();
    }
  }, [currentDrawer, role, showGameSettings, startTimer]);

  // 정답 팝업이 표시될 때 타이머 정지
  useEffect(() => {
    // 무제한 모드이거나 호스트가 아니면 타이머 업데이트 하지 않음
    if (gameSettings.timeLimit === UNLIMITED_TIME || role !== 'host') return;

    if (showCorrectPopup) {
      setIsTimerActive(false);
      updateTimerState({
        timeLeft: timeLeft,
        isActive: false,
        timeLimit: gameSettings.timeLimit
      });
    }
  }, [showCorrectPopup, role, timeLeft, gameSettings.timeLimit, updateTimerState]);

  // 디버깅을 위한 콘솔 로그
  useEffect(() => {
    console.log('Timer conditions:', {
      timeLimit: gameSettings.timeLimit,
      isUnlimited,
      timeLeft,
      isTimerActive
    });
  }, [gameSettings.timeLimit, isUnlimited, timeLeft, isTimerActive]);

  // 디버깅을 위한 콘솔 로그
  useEffect(() => {
    console.log('Current conditions:', {
      timeLimit: gameSettings.timeLimit,
      isUnlimited,
      timeLeft,
      playerID,
      currentDrawer,
      showCorrectPopup,
      canDraw: playerID === currentDrawer && !showCorrectPopup && (
        isUnlimited || timeLeft === -1 || (gameSettings.timeLimit > 0 && timeLeft > 0)
      )
    });
  }, [gameSettings.timeLimit, isUnlimited, timeLeft, playerID, currentDrawer, showCorrectPopup]);

  // 패스 처리 함수 추가
  const handleSkipWord = async () => {
    try {
      // 현재 게임 데이터 가져오기
      const gameDataRef = ref(database, `rooms/${roomCode}/gameData`);
      const snapshot = await get(gameDataRef);
      const gameData = snapshot.val();

      // 다음 단어 인덱스 계산
      const nextIndex = gameData.settings.currentWordIndex + 1;
      const nextWord = gameData.wordList[nextIndex];

      // 다음 단어가 없는 경우 (게임 종료)
      if (!nextWord) {
        await set(ref(database, `rooms/${roomCode}/showRankingPopup`), true);
        return;
      }

      // 다음 drawer 선택
      const playersList = Object.values(players);
      const currentDrawerIndex = playersList.findIndex(p => p.id === currentDrawer);
      const nextDrawerIndex = (currentDrawerIndex + 1) % playersList.length;
      const nextDrawer = playersList[nextDrawerIndex].id;

      // Firebase 업데이트
      await Promise.all([
        set(ref(database, `rooms/${roomCode}/currentDrawer`), nextDrawer),
        set(ref(database, `rooms/${roomCode}/gameData`), {
          ...gameData,
          settings: {
            ...gameData.settings,
            currentWordIndex: nextIndex
          },
          currentWord: nextWord
        }),
        set(ref(database, `rooms/${roomCode}/correctGuess`), language === 'en' ? 'Skipped' : '패스'),
        set(ref(database, `rooms/${roomCode}/showCorrectPopup`), true)
      ]);

      setTimeout(() => {
        set(ref(database, `rooms/${roomCode}/showCorrectPopup`), false);
      }, 3000);

    } catch (error) {
      console.error('Error skipping word:', error);
    }
  };

  // Firebase 리스너 추가
  useEffect(() => {
    const showGameSettingsRef = ref(database, `rooms/${roomCode}/showGameSettings`);
    onValue(showGameSettingsRef, (snapshot) => {
      const data = snapshot.val();
      setShowGameSettings(data);
    });
  }, [roomCode]);

  // 정답 처리 함수 수정
  const handleCorrectAnswer = async (playerName) => {
    try {
      // 현재 게임 데이터 가져오기
      const gameDataRef = ref(database, `rooms/${roomCode}/gameData`);
      const snapshot = await get(gameDataRef);
      const gameData = snapshot.val();

      // 다음 단어 인덱스 계산
      const nextIndex = gameData.settings.currentWordIndex + 1;
      const nextWord = gameData.wordList[nextIndex];
      
      // 점수 업데이트 (10점 추가)
      const currentScore = Number(scores[playerName]) || 0;
      const updatedScores = {
        ...scores,
        [playerName]: currentScore + 10
      };

      // 다음 단어가 없는 경우 (게임 종료)
      if (!nextWord) {
        await Promise.all([
          // 점수 업데이트
          set(ref(database, `rooms/${roomCode}/scores`), updatedScores),
          // 정답자 표시
          set(ref(database, `rooms/${roomCode}/correctGuess`), playerName),
          // 정답 팝업 표시
          set(ref(database, `rooms/${roomCode}/showCorrectPopup`), true),
          // 랭킹 팝업 표시
          set(ref(database, `rooms/${roomCode}/showRankingPopup`), true)
        ]);

        // 3초 후 정답 팝업 숨기기
        setTimeout(() => {
          set(ref(database, `rooms/${roomCode}/showCorrectPopup`), false);
        }, 3000);

        return; // 함수 종료
      }

      // 다음 단어가 있는 경우 기존 로직 실행
      const playersList = Object.values(players);
      const currentDrawerIndex = playersList.findIndex(p => p.id === currentDrawer);
      const nextDrawerIndex = (currentDrawerIndex + 1) % playersList.length;
      const nextDrawer = playersList[nextDrawerIndex].id;

      // Firebase 업데이트
      await Promise.all([
        // 점수 업데이트
        set(ref(database, `rooms/${roomCode}/scores`), updatedScores),
        
        // 다음 그리는 사람 업데이트
        set(ref(database, `rooms/${roomCode}/currentDrawer`), nextDrawer),
        
        // 게임 데이터 업데이트
        set(ref(database, `rooms/${roomCode}/gameData`), {
          ...gameData,
          settings: {
            ...gameData.settings,
            currentWordIndex: nextIndex
          },
          currentWord: nextWord
        }),
        
        // 정답자 표시
        set(ref(database, `rooms/${roomCode}/correctGuess`), playerName),
        
        // 정답 팝업 표시
        set(ref(database, `rooms/${roomCode}/showCorrectPopup`), true)
      ]);

      // 로컬 상태 업데이트
      setCurrentWord(nextWord);

      // 정답 팝업 3초 후 사라짐
      setTimeout(() => {
        set(ref(database, `rooms/${roomCode}/showCorrectPopup`), false);
        // 타이머는 팝업이 사라질 때 useEffect에서 자동으로 시작됨
      }, 3000);

    } catch (error) {
      console.error('Error handling correct answer:', error);
    }
  };

  // 타이핑 정답 체크 함수 수정
  const handleTypingAnswer = async (typedAnswer, answererID) => {
    // 현재 단어 가져오기
    const gameDataRef = ref(database, `rooms/${roomCode}/gameData`);
    const snapshot = await get(gameDataRef);
    const gameData = snapshot.val();
    const currentWord = gameData.currentWord;

    // 정답 비교 (대소문자 무시, 공백 제거)
    if (typedAnswer.trim().toLowerCase() === currentWord.trim().toLowerCase()) {
      // 정답자 찾기
      const answerer = players.find(p => p.id === answererID);
      if (answerer) {
        // 정답 처리
        handleCorrectAnswer(answerer.name);
      }
    }
  };

  // 입력창 이벤트 핸들러 수정
  const handleAnswerSubmit = (e) => {
    e.preventDefault();
    if (answer.trim() !== '') {
      handleTypingAnswer(answer, playerID);
      setAnswer('');
    }
  };

  // 무제한일 때는 timeLeft를 설정하지 않음
  const isTimeUpCondition = gameSettings.timeLimit > 0 && timeLeft <= 0;

  // timeLeft 초기화 로직 수정
  useEffect(() => {
    if (currentDrawer) {
      if (gameSettings.timeLimit > 0) {
        setTimeLeft(gameSettings.timeLimit);
        setIsTimeUp(false);
      } else {
        // 무제한일 때는 timeLeft를 설정하지 않음
        setTimeLeft(undefined);
        setIsTimeUp(false);
      }
    }
  }, [currentDrawer, gameSettings.timeLimit]);

  const handleEndGame = () => {
    setShowEndOptions(true);
  };

  const handleCompleteEnd = () => {
    setShowEndOptions(false);
    endGame();
  };

  // 타이머 초기화 및 설정 함수
  const initializeTimer = useCallback(() => {
    if (!roomCode || role !== 'host') return;

    const timerRef = ref(database, `rooms/${roomCode}/timer`);
    
    if (gameSettings.timeLimit === '-1' || gameSettings.timeLimit === -1) {
      // 무제한 모드일 때는 타이머 데이터를 null로 설정
      set(timerRef, null)
        .then(() => {
          setTimeLeft(null);
          setIsTimerActive(false);
        })
        .catch(error => console.error('Timer reset error:', error));
    } else {
      // 타이머 모드일 때 초기 상태 설정
      const initialTimerState = {
        timeLeft: Number(gameSettings.timeLimit),
        isActive: true,  // 게임 작 시 바로 타이머 활성화
        timeLimit: Number(gameSettings.timeLimit)
      };
      
      set(timerRef, initialTimerState)
        .then(() => {
          setTimeLeft(gameSettings.timeLimit);
          setIsTimerActive(true);
        })
        .catch(error => console.error('Timer init error:', error));
    }
  }, [roomCode, role, gameSettings.timeLimit]);

  // 타이머 구독
  useEffect(() => {
    if (!roomCode) return;

    const timerRef = ref(database, `rooms/${roomCode}/timer`);
    const unsubscribe = onValue(timerRef, (snapshot) => {
      const data = snapshot.val();
      
      if (!data) {
        // 타이머 데이터가 없으면 무제한 모드
        setIsUnlimited(true);
        setTimeLeft(null);
        setIsTimerActive(false);
      } else if (typeof data.timeLeft === 'number') {
        // 타이머 데이터가 있으면 타이머 모드
        setIsUnlimited(false);
        setTimeLeft(data.timeLeft);
        setIsTimerActive(Boolean(data.isActive));
      }
    });

    return () => unsubscribe();
  }, [roomCode]);

  // 게임 시작 시 타이머 설정
  useEffect(() => {
    if (role === 'host' && currentDrawer && !isUnlimited) {
      const timerRef = ref(database, `rooms/${roomCode}/timer`);
      set(timerRef, {
        timeLeft: gameSettings.timeLimit,
        isActive: true,
        timeLimit: gameSettings.timeLimit
      });
    }
  }, [currentDrawer, gameSettings.timeLimit, isUnlimited, role, roomCode]);

  // 점수 리셋 함수 수정
  const resetScores = useCallback(() => {
    const newScores = {};
    players.forEach(player => {
      newScores[player.name] = 0;
    });
    
    if (role === 'host') {
      set(ref(database, `rooms/${roomCode}/scores`), newScores)
        .then(() => {
          setScores(newScores);
        })
        .catch(error => console.error('Score reset error:', error));
    }
  }, [players, role, roomCode, setScores]);

  // 게임 재시작 함수 수정
  const handleRestartWithCurrentMembers = async () => {
    try {
      // 기존 점수와 현재 단어 초기화
      await set(ref(database, `rooms/${roomCode}/scores`), {});
      await set(ref(database, `rooms/${roomCode}/currentDrawer`), null);
      
      // 게임 설정 화면 표시
      await set(ref(database, `rooms/${roomCode}/showGameSettings`), true);
      setShowGameSettings(true);
      setShowEndOptions(false);
    } catch (error) {
      console.error('Game restart error:', error);
    }
  };

  // 리플레이 함수 수정
  const handleReplay = async () => {
    setShowRankingPopup(false);
    handleRestartWithCurrentMembers();  // 재시작 함수 재사용
  };

  const handleFirstTime = () => {
    setShowRankingPopup(false);
    setStep('join');
  };

  // 게임 설정 변경 감지
  useEffect(() => {
    if (!roomCode || !role === 'host') return;

    // 게임 설정이 변경될 때마다 타이머 상태 업데이트
    const updateTimerState = () => {
      const timerRef = ref(database, `rooms/${roomCode}/timer`);
      
      if (gameSettings.timeLimit === UNLIMITED_TIME) {
        // 무제한 모드일 때는 타이머 데이터 삭제
        set(timerRef, null);
      } else {
        // 타이머 모드일 때는 초기 상태 설정
        const initialTimerState = {
          timeLeft: Number(gameSettings.timeLimit),
          isActive: true,
          timeLimit: Number(gameSettings.timeLimit)
        };
        
        set(timerRef, initialTimerState)
          .catch(error => console.error('Timer state update error:', error));
      }
    };

    // 설정 변경 시 타이머 상태 업데이트
    if (showGameSettings) {
      const newScores = {};
      players.forEach(player => {
        newScores[player.name] = 0;
      });
      
      set(ref(database, `rooms/${roomCode}/scores`), newScores);
      setScores(newScores);
      updateTimerState();
    }
  }, [gameSettings.timeLimit, role, roomCode, showGameSettings]);

  // 타이머 관련 useEffect 수정
  useEffect(() => {
    if (!roomCode) return;

    // 게임 설정 변경 감지
    const gameSettingsRef = ref(database, `rooms/${roomCode}/gameData/settings`);
    const unsubscribeSettings = onValue(gameSettingsRef, (snapshot) => {
      const settings = snapshot.val();
      if (settings) {
        setGameSettings(settings);
      }
    });

    // 타이머 상태 감지
    const timerRef = ref(database, `rooms/${roomCode}/timer`);
    const unsubscribeTimer = onValue(timerRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTimeLeft(data.timeLeft);
        setIsTimerActive(data.isActive);
      } else {
        // 타이머 데이터가 null일 경우 (무제한 모드)
        setTimeLeft(null);
        setIsTimerActive(false);
      }
    });

    return () => {
      unsubscribeSettings();
      unsubscribeTimer();
    };
  }, [roomCode, setGameSettings]);

  return (
    <div className="game-screen">
      <div className="game-content">
        <div className="game-layout">
          <div className="players-section">
            {playerID === currentDrawer ? (
              <PlayerListForDrawer 
                players={players} 
                currentDrawer={currentDrawer} 
                scores={scores} 
                handleMarkCorrect={handleMarkCorrect}
              />
            ) : (
              <PlayerListForNonDrawer 
                players={players} 
                currentDrawer={currentDrawer} 
                scores={scores}
              />
            )}
            
            <div className="keyword-input-section">
              {playerID === currentDrawer ? (
                <div className="current-keyword">
                  <div className="keyword-container">
                    <span>{currentWord}</span>
                    <button 
                      className="skip-button"
                      onClick={handleSkipWord}
                      disabled={showCorrectPopup}
                    >
                      {language === 'en' ? 'Skip' : '패스'}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="answer-input">
                  <form onSubmit={handleAnswerSubmit}>
                    <input
                      type="text"
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      placeholder={language === 'en' ? "Type your answer..." : "정답을 입력하세요..."}
                      autoComplete="off"
                      disabled={!canType || showCorrectPopup}
                    />
                  </form>
                </div>
              )}
              {(gameSettings.timeLimit !== '-1' && gameSettings.timeLimit !== -1) && (
                <Timer 
                  timeLimit={gameSettings.timeLimit}
                  timeLeft={timeLeft}
                />
              )}
            </div>
          </div>

          <div className="whiteboard-section">
            <Whiteboard2 
              canDraw={canDraw}
              isTimeUp={!isUnlimited && timeLeft === 0}
              clearCanvas={showGameSettings}
            />
          </div>
        </div>

        {role === 'host' && (
          <div className="end-game">
            <button onClick={handleEndGame}>End</button>
          </div>
        )}
      </div>

      {showCorrectPopup && (
        <div className="popup-overlay">
          <div className="correct-popup">
            {language === 'en' ? `Correct keyword guessed by ${correctGuess}!` : `${correctGuess}님 정답!`}
          </div>
        </div>
      )}

      {showEndOptions && (
        <div className="popup-overlay">
          <div className="confirm-popup end-popup">
            <div className="popup-header">
              <h3>{language === 'en' ? 'End Game Options' : '게임 종료 옵션'}</h3>
              <button 
                className="close-button"
                onClick={() => setShowEndOptions(false)}
              >
                ×
              </button>
            </div>
            <div className="confirm-actions end-options">
              <button 
                className="confirm-button"
                onClick={handleRestartWithCurrentMembers}
              >
                {language === 'en' ? 'Restart with current members' : '현재 멤버로 재시작'}
              </button>
              <button 
                className="cancel-button"
                onClick={handleCompleteEnd}
              >
                {language === 'en' ? 'End and return to home' : '완전 종료'}
              </button>
            </div>
          </div>
        </div>
      )}

      {showRankingPopup && (
        <div className="popup-overlay">
          <RankingPopup 
            scores={scores} 
            onReplay={handleReplay} 
            onFirstTime={handleFirstTime} 
            isHost={role === 'host'}
          />
        </div>
      )}
      {/* 정답 팝업 */}

      {showGameSettings && (
        <>
          <div className="settings-overlay"></div>  {/* 회색 배경을 위한 오버레이 추가 */}
          <div className="settings-modal">
            {role === 'host' ? (
              <GameSettings 
                isHost={true}
                onStartGame={() => {
                  set(ref(database, `rooms/${roomCode}/showGameSettings`), false);
                }}
                onClose={() => {
                  set(ref(database, `rooms/${roomCode}/showGameSettings`), false);
                }}
              />
            ) : (
              <div className="waiting-message">
                <p>{language === 'en' ? 'Waiting for host to set up the game...' : '호스트가 게임을 설정하고 있습니다...'}</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GameScreen;
