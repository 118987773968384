import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../components/LanguageContext';
import appStoreBadge from '../images/appstore.svg';
import playStoreBadge from '../images/playstore.png';
import './LandingPage.css';

function LandingPage() {
  const { language } = useLanguage();

  return (
    <div className="landing-page">
      <div className="landing-container">
        <div className="corner corner-tl"></div>
        <div className="corner corner-tr"></div>
        <div className="corner corner-bl"></div>
        <div className="corner corner-br"></div>
        
        <div className="logo-section">
          <h1>dodoodle</h1>
        </div>
        <h2>
          {language === 'en' 
            ? 'Draw and Guess!' 
            : '친구들과 함께 그리고 맞춰보세요!'}
        </h2>
        <h4> Keyword will be made with AI </h4>
        <Link to="/play" className="play-button">
          {language === 'en' ? 'Start Playing' : '게임 시작'}
        </Link>
      </div>
      <div className="store-info" data-text={language === 'en' ? 'Coming soon' : '곧 출시됩니다'}>
        <div className="store-icons">
          <img src={appStoreBadge} alt="App Store" />
          <img src={playStoreBadge} alt="Play Store" />
        </div>
      </div>
    </div>
  );
}

export default LandingPage; 